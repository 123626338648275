import React from 'react';
import Landing from '../components/landing';
import Intro from '../components/intro';
import About from '../components/about';
import Grid from '../components/grid';
import Services from '../components/services';
import Team from '../components/team';
import Testimonials from '../components/testimonials';

import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => (
    <Layout>
        <Seo title="Home"/>
        <Landing/>
        <Intro/>
        <About/>
        <Grid/>
        <Services/>
        <Team/>
        <Testimonials/>
    </Layout>
);

export default IndexPage;
