import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import { FaQuoteLeft } from "react-icons/fa"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      goldMountain: file(relativePath: { eq: "gold-mountain-logo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      enduro: file(relativePath: { eq: "enduro-logo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      skeena: file(relativePath: { eq: "skeena-logo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const goldMountain = data.goldMountain.childImageSharp.gatsbyImageData
  const enduro = data.enduro.childImageSharp.gatsbyImageData
  const skeena = data.skeena.childImageSharp.gatsbyImageData

  const settings = {
    className: "md:my-8",
    infinite: true,
    dots: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <BsChevronLeft className="text-red w-12 h-12" />
        </div>
      </div>
    ),
    nextArrow: (
      <div>
        <div className="prev-slick-arrow">
          <BsChevronRight className="text-red w-12 h-12" />
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <section
      id="testimonials"
      className="container mx-auto py-10 px-6 lg:px-10"
    >
      <div className="w-full text-center">
        <h1 className="halogen uppercase text-black text-3xl lg:text-4xl">
          Testimonials
        </h1>
      </div>
      <Slider {...settings}>
        <div>
          <div className="h-full flex flex-col items-center">
            <div className="bg-white h-20 w-40 border shadow-lg flex translate-y-12 p-4">
              <GatsbyImage
                image={goldMountain}
                alt="Gold Mountain Mining Logo"
              />
            </div>
            <div className="flex h-full p-5 md:p-10 border">
              <div className="mt-10">
                <h3 className="mt-4 text-lg font-semibold capitalize">
                  Grant Carlson, P.Eng
                </h3>
                <h4 className="text-sm text-gray-700">
                  Chief Operating Officer
                </h4>
                <h4 className="text-sm text-gray-700">Gold Mountain Mining</h4>
                <blockquote class="relative mt-10 text-md lg:text-left italic font-semibold text-gray-900">
                  <FaQuoteLeft className="absolute left-0 transform -translate-x-2 -translate-y-4 h-8 w-8 text-red opacity-10" />
                  <p className="relative">
                    Gold Mountain has worked with Helm Diamond Drilling for two
                    years at the Elk Gold Project, and Helm has been
                    professional and efficient in delivering our exploration
                    programs over that time. HELM's crews have been experienced
                    and talented drillers delivering excellent productivity and
                    unit costs and most importantly, maintaining an outstanding
                    health and safety record.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="h-full flex flex-col items-center">
            <div className="bg-white h-20 w-40 border shadow-lg flex translate-y-12 p-4">
              <GatsbyImage image={enduro} alt="Enduro Logo" />
            </div>
            <div className="flex h-full p-5 md:p-10 border">
              <div className="mt-10">
                <h3 className="mt-4 text-lg font-semibold capitalize">
                  Dylan Hunko
                </h3>
                <h4 className="text-sm text-gray-700">
                  Chief Operating Officer
                </h4>
                <h4 className="text-sm text-gray-700">Enduro Metals</h4>
                <blockquote class="relative mt-10 text-md lg:text-left italic font-semibold text-gray-900">
                  <FaQuoteLeft className="absolute left-0 transform -translate-x-2 -translate-y-4 h-8 w-8 text-red opacity-10" />
                  <p className="relative">
                    HELM Diamond Drilling has provided Enduro Metals with
                    exceptional diamond drilling services since 2021. The
                    equipment, logistics and more importantly the drilling teams
                    at HELM allow for a seamless operation that not only
                    improves drilling efficiency but decreases overall cost per
                    meter to ensure that the client is maximizing their
                    exploration window. Enduro Metals looks forward to working
                    with HELM Diamond Drilling for many years to come.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex h-full flex-col items-center">
            <div className="bg-white h-20 w-40 border shadow-lg flex translate-y-12 p-4">
              <GatsbyImage image={skeena} alt="Skeena Resources Logo" />
            </div>
            <div className="flex h-full p-5 md:p-10 border">
              <div className="mt-10">
                <h3 className="mt-4 text-lg font-semibold capitalize">
                  Adrian Newton, P.Geo
                </h3>
                <h4 className="text-sm text-gray-700">
                  Director of Exploration
                </h4>
                <h4 className="text-sm text-gray-700">Skeena Resources</h4>
                <blockquote class="relative mt-10 text-md lg:text-left italic font-semibold text-gray-900">
                  <FaQuoteLeft className="absolute left-0 transform -translate-x-2 -translate-y-4 h-8 w-8 text-red opacity-10" />
                  <p className="relative">
                    We have had the pleasure of working with Helm Diamond
                    Drilling for the past two years on the Eskay Creek project
                    using both their heli-portable and skid-mounted Multi-Power
                    Discovery II drills in a wide range of conditions. Their
                    team is knowledgeable, resourceful and experienced and their
                    equipment is always maintained in like-new condition and
                    clean. Their drilling production rates have been
                    consistently the highest among the groups working on the
                    project, which translates into achieving our objectives more
                    efficiently from a time and cost perspective. The electronic
                    drill shift reports and approval system Helm employs is
                    transparent and greatly streamlines our workflow, allowing
                    us to focus more time on the technical aspects of the
                    project. Skeena Resources looks forward to continuing to
                    work with Helm Drilling in the future, and I would gladly
                    recommend their services to others.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  )
}

export default Testimonials
