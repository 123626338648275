import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { BgImage } from "gbimage-bridge"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "about-img.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const aboutImg = data.aboutImage.childImageSharp.gatsbyImageData
  const aboutText = `HELM Diamond Drilling is a newly established private Canadian
company with over 30 years of combined drilling experience. HELM
is dedicated to providing high quality drilling services based on
their accomplished team and top of the line drilling equipment.
Based in Prince George BC, Helm is an Indigenously owned company
by Alan McPherson, a member of the Tahltan Nation. This places
Helm on a local contractors list, making HELM a favourable hire in
one of the most explored and prolific areas in BC.`

  return (
    <section id="about" className="md:pb-28">
      <div className="hidden md:grid grid-cols-12 grid-rows-6 md:items-center w-full h-screen">
        <BgImage
          image={aboutImg}
          className="col-span-12 row-span-5"
          style={{
            minWidth: "100%",
            minHeight: "100%",
          }}
        />
        <div className="col-span-12 row-span-1 md:col-span-8 md:col-start-2 lg:col-span-6 lg:col-start-2 z-10 ">
          <div className="flex flex-col justify-center px-6 py-12 md:p-8 bg-white shadow-lg -mt-48">
            <h2 className="halogen uppercase text-black text-3xl lg:text-4xl heading">
              About Us
            </h2>
            <p className="my-5 text-lg">{aboutText}</p>
            <AnchorLink
              className="w-max"
              to="/#services"
              title="Next Section"
              smooth
              duration={1100}
            >
              <button type="button" className="btn-md">
                Services
              </button>
            </AnchorLink>
          </div>
        </div>
      </div>
      <div className="md:hidden w-full h-screen">
        <BgImage
          image={aboutImg}
          style={{
            minWidth: "100%",
            minHeight: "100%",
          }}
        >
          <div className="bg-black bg-opacity-75 shadow-lg h-screen w-full flex flex-col justify-center p-10 text-white ">
            <h2 className="halogen uppercase text-3xl lg:text-4xl heading">
              About Us
            </h2>
            <p className="my-5 text-lg">{aboutText}</p>
            <AnchorLink
              to="/#services"
              title="Next Section"
              smooth
              duration={1100}
            >
              <button type="button" className="btn-md">
                Services
              </button>
            </AnchorLink>
          </div>
        </BgImage>
      </div>
    </section>
  )
}

export default About
