import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image"
import useHover from "./hooks/useHover"

const Intro = () => {
  const data = useStaticQuery(graphql`
    query Intro {
      employment: file(relativePath: { eq: "employment.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      team: file(relativePath: { eq: "team.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      contact: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  const employmentImg = data.employment.childImageSharp.gatsbyImageData
  const teamImg = data.team.childImageSharp.gatsbyImageData
  const contactImg = data.contact.childImageSharp.gatsbyImageData

  const [hoverRef1, isHovered1] = useHover()

  const [hoverRef2, isHovered2] = useHover()

  const [hoverRef3, isHovered3] = useHover()

  return (
    <section id="intro">
      <div className="container-lg mx-auto py-12 lg:py-24 px-10">
        <div className="flex flex-col h-full w-full text-center items-center justify-center">
          <h2 className="halogen uppercase text-black text-3xl lg:text-4xl">
            Indigenous Owned and Operated
          </h2>
          <p className="my-10 text-lg max-w-6xl">
            HELM Diamond Drilling is an Indigenous-owned mining company started
            by Alan McPherson in 2020. He has over 20 years of drilling
            experience in Canada and has assembled an accomplished team combined
            with top of the line drills and equipment.
          </p>
          <AnchorLink to="/#about" title="Next Section" smooth duration={1100}>
            <button type="button" className="btn-md">
              About
            </button>
          </AnchorLink>
        </div>
      </div>
      <div className="p-5 lg:p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 bg-darkRed">
        <div className="rounded bg-white overflow-hidden shadow-lg">
          <GatsbyImage
            objectFit="cover"
            objectPosition="top"
            className="h-80 md:h-40 lg:h-72 w-full"
            imgClassName={`zoom-item ${isHovered1 ? "zoomed" : ""}`}
            image={employmentImg}
            alt="Employment Image"
          />
          <div className="px-6 py-4">
            <div className="text-xl mb-2 halogen">Job Opportunities</div>
            <p className="text-gray-800 text-base">
              New positions are always being added and we are frequently looking
              for new Drillers and Driller Helpers to join our expanding dynamic
              team.
            </p>
          </div>
          <div className="px-6 pb-4 w-max">
            <Link to="/employment" title="Job Opportunities">
              <button ref={hoverRef1} className="btn-xs">
                Apply
              </button>
            </Link>
          </div>
        </div>
        <div className="rounded bg-white overflow-hidden shadow-lg">
          <GatsbyImage
            objectFit="cover"
            objectPosition="center"
            className="h-80 md:h-40 lg:h-72 w-full"
            imgClassName={`zoom-item ${isHovered2 ? "zoomed" : ""}`}
            image={teamImg}
            alt="Team Image"
          />
          <div className="px-6 py-4">
            <div className="text-xl mb-2 halogen">Leadership Team</div>
            <p className="text-gray-800 text-base">
              Our team has drilled all over Canada on skid, underground, fly,
              ice and barge. We are a growing company with experiences staff who
              want to tackle all types of jobs where we can continue to grow and
              learn.
            </p>
          </div>
          <div className="px-6 pb-6 w-max">
            <AnchorLink to="/#team" title="Our Team">
              <button ref={hoverRef2} className="btn-xs">
                Meet the Team
              </button>
            </AnchorLink>
          </div>
        </div>
        <div className="rounded bg-white overflow-hidden shadow-lg">
          <GatsbyImage
            objectFit="cover"
            objectPosition="center"
            className="h-80 md:h-40 lg:h-72 w-full"
            imgClassName={`zoom-item ${isHovered3 ? "zoomed" : ""}`}
            image={contactImg}
            alt="Contact Image"
          />
          <div className="px-6 py-4">
            <div className="text-xl mb-2 halogen">Get in Touch</div>
            <p className="text-gray-800 text-base">
              For all inquries, including quotes, availability and any other
              questions you may have for you next project, contact us today!
            </p>
          </div>
          <div className="px-6 pb-6 w-max">
            <Link to="/contact" title="Contact Us">
              <button ref={hoverRef3} className="btn-xs">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
