import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

const Grid = () => {
    const data = useStaticQuery(graphql `
    query {
        grid1: file(relativePath: {eq: "grid-1.jpg"}) {
          childImageSharp {
            gatsbyImageData
          }
        }
        grid2: file(relativePath: {eq: "grid-2.jpg"}) {
          childImageSharp {
            gatsbyImageData
          }
        }
        grid3: file(relativePath: {eq: "grid-3.jpg"}) {
          childImageSharp {
            gatsbyImageData
          }
        }
        grid4: file(relativePath: {eq: "grid-4.jpg"}) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }      
  `);

    const gridImg1 = data.grid1.childImageSharp.gatsbyImageData;
    const gridImg2 = data.grid2.childImageSharp.gatsbyImageData;
    const gridImg3 = data.grid3.childImageSharp.gatsbyImageData;
    const gridImg4 = data.grid4.childImageSharp.gatsbyImageData;

    return (
        <section id="grid">
            <div className="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <GatsbyImage image={gridImg1} alt="Grid Image"/>
                <GatsbyImage image={gridImg2} alt="Grid Image"/>
                <GatsbyImage image={gridImg3} alt="Grid Image"/>
                <GatsbyImage image={gridImg4} alt="Grid Image"/>
            </div>
        </section>
    );
};

export default Grid;