import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      services: file(relativePath: { eq: "services.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const services = data.services.childImageSharp.gatsbyImageData

  return (
    <section id="services" className="lg:pb-12">
      <div className="w-full text-center p-10">
        <h1 className="halogen uppercase text-black text-3xl lg:text-4xl">
          Our Services
        </h1>
      </div>
      <div className="lg:container lg:mx-auto lg:flex lg:px-10">
        <GatsbyImage
          image={services}
          alt="Services Image"
          className="h-auto lg:w-1/2 flex-none bg-cover text-center items-center"
        />
        <div className="border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light px-6 py-12 flex flex-col justify-center">
          <h2 className="halogen uppercase text-black text-2xl heading">
            Diamond Drilling
          </h2>
          <p className="my-5 text-lg">
            Specializing in mineral exploration, underground, and production
            diamond drilling, Helm Diamond Drilling takes pride in safety,
            efficiency and forward thinking solutions. We consistently deliver
            innovative problem solving to the most technical drilling
            challenges, in some of the most complex operating environments in
            Canada. Through our investment in safety, people and industry
            leading equipment we are ready to take on any challenge you may
            have.
          </p>
          <div className="bg-darkRed text-white py-10 px-5 shadow-xl">
            <h3 className="halogen uppercase text-base mb-5">Our Drills</h3>
            <ul className="list-disc pl-5">
              <li className="pb-2">
                We currently have three Multi-Power Discovery II drills capable
                of drilling to 1285 meters with NQ and up to 875 meters with HQ
                with plans for further expansion in the near future
              </li>
              <li className="pb-2">Diamond core drilling BQ, NQ, HQ</li>
              <li className="pb-2">Configured for fly, skid and underground</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
