import React, { Fragment, useState } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { motion } from "framer-motion"

import { Dialog, Transition } from "@headlessui/react"
import Embed from "./video"

import { IconContext } from "react-icons"
import { BsChevronDown } from "react-icons/bs"

const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #000;
  height: 100vh;

  @media only screen and (max-width: 768px) {
    height: 87vh;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Landing = () => {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const bgVariants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 1,
        when: "beforeChildren",
      },
    },
  }

  return (
    <>
      <section id="hero">
        <VideoContainer className="relative w-full">
          <motion.div variants={bgVariants} initial="initial" animate="enter">
            <video
              className="video-player"
              id="bgvideo"
              poster="https://res.cloudinary.com/seebass3/image/upload/v1639336440/helm-drilling/video-poster_syybhs.jpg"
              autoPlay
              loop
              muted
              playsInline
            >
              <source
                src="https://res.cloudinary.com/seebass3/video/upload/v1638767328/helm-drilling/Helm_Video_Loop_bciyck.mp4"
                type="video/mp4"
              />
            </video>
          </motion.div>
          <div className="absolute flex flex-col items-center justify-center h-full w-full p-8 pt-topMobile lg:pt-top">
            <h1 className="halogen uppercase text-white text-4xl md:text-5xl lg:text-6xl text-center">
              Helm Diamond Drilling
            </h1>
            {/* <button className="btn-lg mt-4" onClick={openModal}>
              Learn More
            </button> */}
            <IconContext.Provider
              value={{
                color: "white",
                className: "w-16 h-16 inline-block",
                style: {
                  verticalAlign: "middle",
                },
              }}
            >
              <AnchorLink
                to="/#intro"
                title="Next Section"
                smooth
                duration={1100}
              >
                <button
                  aria-label="Next Section"
                  type="button"
                  className="absolute bottom-0 left-0 w-full text-center mb-4 cursor-pointer"
                >
                  <BsChevronDown />
                </button>
              </AnchorLink>
            </IconContext.Provider>
          </div>
        </VideoContainer>
      </section>
      {/* <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-50" onClose={closeModal}>
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50"/>
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0"/>
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div
                                className="inline-block w-full max-w-6xl overflow-hidden text-left align-middle transition-all transform bg-black shadow-xl">
                                <Embed source="https://www.youtube.com/embed/uR7u4CVCsw8?autoplay=1"/></div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>  */}
    </>
  )
}

export default Landing
