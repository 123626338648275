import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      alan: file(relativePath: { eq: "alan.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      alec: file(relativePath: { eq: "alec.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const alan = data.alan.childImageSharp.gatsbyImageData
  const alec = data.alec.childImageSharp.gatsbyImageData

  return (
    <section id="team" className="bg-darkRed">
      <div className="container px-6 py-10 mx-auto text-white">
        <div className="xl:flex xl:items-center xL:-mx-4">
          <div className="xl:w-1/2 xl:mx-4">
            <h1 className="halogen uppercase halogen text-2xl lg:text-3xl">
              Our Team
            </h1>

            <p className="max-w-2xl mt-4 dark:text-gray-300">
              HELM is committed in building a company that grows with experience
              and knowledgable staff. HELM is motivated to help more indigenous
              individuals enter into the industry with proper training to
              provide long careers.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-0 xl:mx-4 xl:w-2/3 md:grid-cols-2">
            <div className="shadow-lg bg-black">
              <GatsbyImage
                objectFit="cover"
                objectPosition="top"
                className="w-full h-64"
                image={alan}
                alt="Alan McPherson"
              />
              <div className="px-6 py-4">
                <h2 className="text-2xl font-semibold capitalize dark:text-white">
                  Alan McPherson
                </h2>
                <p className="mt-2 capitalize underline">Owner/President</p>
                <p className="mt-2 text-sm">
                  Alan is a Tahltan First Nation Member from Northern British
                  Columbia. Starting his career in 2005 as a drillers helper he
                  worked his way up through the industry, working at locations
                  throughout Canada and building on his experience with skid,
                  fly and underground drilling. In late 2020 Alan opened his own
                  drilling company, offering his years of experience and
                  knowledge to local industry with the goal of local employment
                  and safe, reputable results.
                </p>
              </div>
            </div>
            <div className="shadow-lg bg-black">
              <GatsbyImage
                objectFit="cover"
                objectPosition="center"
                className="h-64 w-full aspect-square"
                image={alec}
                alt="Alec Fischer "
              />
              <div className="px-6 py-4">
                <h2 className="mt-4 text-2xl font-semibold capitalize dark:text-white">
                  Alec Fischer
                </h2>
                <p className="mt-2 capitalize underline">
                  Chief Executive Officer
                </p>
                <p className="mt-2 text-sm">
                  Alec comes to us from Manitoba, where he completed a degree in
                  Geology and has since worked throughout the Mining and
                  Exploration industry in several provinces across Canada, with
                  experience planning drill campaigns and managing projects he
                  is happy to help bridge the gap between drilling and geology.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
