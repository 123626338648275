import React from 'react';
import PropTypes from 'prop-types';

const Embed = ({ source }) => (
  
  <div className="video-responsive">
    <iframe
      src={`${source}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Helm Diamond Drilling"
    />
  </div>
);

Embed.propTypes = {
  source: PropTypes.string.isRequired
};

export default Embed;